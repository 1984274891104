/// <reference path="../../node_modules/@types/jquery/index.d.ts" />
/// <reference path="../../node_modules/@types/jquery.validation/index.d.ts" />

$('a.login-box__go-back-btn:not([href]), a.login-box__go-back-btn[href=""]').on("click", function () {
    history.back();
});

$("form").on("submit", function(e) {
    let $form = $(this);

    if($form.data('submitted') === true) {
        e.preventDefault();
    }
});